
hr {
    border-top: 1px solid #AFB1B6;
    opacity: 0.2;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

.modal-image {
    width: 90%;
    height: 90%;
    object-fit: contain;
}
.button-common {
    width: 30px; 
    height: 30px; 
    border: none;
    border-radius: 50%; 
    padding: 20px;
    display: flex;
    align-items: center; 
    justify-content: center; 
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.expense-image-button {
    margin-left: 12px;
}

.expense-image-button:hover,
.close-button:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.expense-image-button img {
    width: 20px;
    height: 20px;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.5); 
    color: white; 
}

.transaction {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 10px 0px;
}

.transaction .name {
    width: 40%;
    display: flex;
    align-items: center;
}

.transaction .icon-state {
    /* padding-right: 10px; */
    width: 40px;
}

.transaction .date {
    width: 20%;
    font-weight: 500;
}

.transaction .amount {
    flex-grow: 1;
    min-width: 100px;
}

.transaction .status-col {
    width: 120px;
}

.transaction .name .title {
    font-weight: 600;
}

.subtitle {
    font-size: small;
    color: #61646B;
}

.round {
    border-radius: 8px;
}

.transaction .status-col .status {
    flex-shrink: 1;
    display:inline;
    border-radius: 8px;
    padding: 5px 10px;
}

.paid {
    background-color: #1C8D7026;
    color: #15765E;
}

.pending {
    background-color: #F8AA4F4A;
    color: #E17F0B;
}

.unpaid {
    background-color: #C7300B26;
    color: #C83511CC;
}

@media only screen and (max-width: 800px) {
    .transaction .status-col {
        display: none;
        width: 0px;
    }

    .transaction .date {
        display: none;
        width: 0px;
    }
    .transaction .name {
        flex-grow: 1;
    }
    .transaction .amount {
        flex-grow: 0;
    }
}