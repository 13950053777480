@font-face {
    font-family: 'Nunito';
    src: url(https://fonts.googleapis.com/css?family=Nunito);
  } 

  .footer__bg {
    background-color: #1C8D70;
    background-size: cover;
    background-position: right top;
    margin-top: 200px;
  }

  .contact__section {
    padding: 75px 0;
  }

.info__section {
  padding: 45px 0px;
}

  .row__email {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -15px;
    margin-left: -15px;
  }

  .contact__section .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

 .row__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .col__info {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .download_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
  }

  .download_text h2 {
    color: #ffffff;
    font-size: 3rem;
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .google_badge a {
    display: flex;
    height: 100px;
    padding: 0px 0px 20px 20px;
  }

  .ios_badge a {
    display: flex;
    height: 80px;
    padding: 0px 20px 20px 0px;
  }

  .rustore_badge a {
    display: flex;
    height: 90px;
    padding: 0px 0px 20px 20px;
  }

  @media (max-width: 768px) {
  .row__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .col__info {
    flex: 0 0 25%;
    max-width: 100%;
  }

  .ios_badge a {
    padding: 0px 0px 20px 0px;
  }

  .google_badge a {
    padding: 0px 0px 20px 10px;
  }
}

  .button__container {
    display: flex;
    justify-content: center;
  }

  .row__info h4 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.5rem;
    color: #ffffff;
  }

  .row__info ul {
    padding-left: 15px;
    margin-top: 0px;
    margin-bottom: 1rem;
  }

  .row__info ul li {
    list-style-type: none;
    margin: 5px 0px;
  }

  .row__info a {
    color:#ffffff;
    text-decoration: none;
    font-size: 1rema;
  }

@media (max-width: 760px) {
  .row__info > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    text-align: center;
  }
}

  .info__contact .img__box {
    width: 35px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
  }

  .info__contact .img__box img {
    height: auto;
    margin-right: 12px;
  }

  .info__contact a {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 20px 0px;
  }

  .info__contact a {
    margin-top: auto;
  }

  .button__container .button {
  display: inline-block;
    padding: 8px 35px;
    background-color: #ffffff;
    color: #1C8D70;
    border-radius: 30px;
    text-transform: uppercase;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    margin-top: 15px;
    font-size: 15px;
  }

  .button__container button:hover {

    background-color: #17a2b8;
    color: white;
  }

  .container__fluid__footer {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
  }

  @media (max-width: 760px) {
    .container__inner {
    width: 100%;
    max-width: 100%;
    text-align: center;
    }


}

  .container__inner p {
    color: rgb(255, 255, 255);
    text-align: center;
    margin: 0px auto;
    padding: 20px;
    font-size: 0.8rem;
  }




