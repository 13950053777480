@font-face {
  font-family: 'Nunito';
  src: url(https://fonts.googleapis.com/css?family=Nunito);
}

@mixin burgerSpan {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #ffffff;
  }
  
  body {
  margin: 0;
  padding: 0;

  }
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    background: #1C8D70;
  }
  
  .logotype {
    font-family: sans-serif;
    font-size: 24px;
    color: #ffffff;
    
  }
  
  nav {
    display: flex;
    align-items: center;
  
    @media screen and (max-width: 48em) {
      nav {display: inline;}
    }
    
  }

  .menu__box li a {
    color: #ffffff;
    padding: 8px 30px;
    border-radius: 30px;
    font-size: 18px;
    transition-duration: 0.5s;
    &:hover {background-color: white; color: #1C8D70}
}

@media (max-width: 48em) {
  .menu__box li a {
    border-radius: 0px;
  }
}
  
  .menu__box {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    
    
    @media screen and (max-width: 48em) {
      flex-direction: column;
      position: fixed;
      visibility: hidden;
      top: 0;
      left: 0;
      width: 100%;
      height: 15em;
      margin: -600px 0 0 0;
      padding: 0.6em 0;
      text-align: center;
      background-color: #2d303a;
      box-shadow: 1px 0 6px rgba(0, 0, 0, .2);
      z-index: 1;
      transition-duration: 0.5s;

    }

  }
  
  /*.red {color: #f06c64;}*/
  
  .menu__item {
    display: flex;
    flex-direction: column;
    padding: 0 1em 0 1.9em;
    color: #ffffff;
    font-family: 'Nunito';
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    text-transform: none;
    
    @media screen and (max-width: 768px) {
      display: block;
      padding: 12px 24px;
      color: #ffffff;
      font-size: 18px;
      transition-duration: 0.5s;
      &:hover {background-color: white;}
      
    }
    
  }
  
  #menu__toggle {
    opacity: 0;
    
    &:checked ~ .menu__btn > span {
      transform: rotate(45deg);
      }
  
    &:checked ~ .menu__btn > span::before {
      top: 0;
      transform: rotate(0);
      background: #f06c64;
    }
  
      &:checked ~ .menu__btn > span::after {
        top: 0;
        transform: rotate(90deg);
        background: #f06c64;
    }
  
      &:checked ~ .menu__box {
        visibility: visible;
        left: 0;
        margin: 0;
    }
  
  }
  
  .menu__btn {
    transition-duration: 0.25s;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 2px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 2;
    
    span {
      @include burgerSpan;
      
      &::before {
        @include burgerSpan;
        content: '';
        top: -8px;
        left: 0;
        right: auto;
        transition-duration: 0.25s;
      }
  
      &::after {
        @include burgerSpan;
        content: '';
        top: 8px;
        left: 0;
        transition-duration: 0.25s;
      }
    
    } 
    
    @media screen and (min-width: 768px) {display: none;}
  
  }
  