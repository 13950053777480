.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.not-found-container h1 {
  margin: 1em 0;
  font-size: 2.5em;
}

.not-found-container p {
  margin: 1em 0;
}

.go-back-button {
  background-color: #1c8d70;
  color: white;
  border: none;
  padding: 1em 2em;
  text-align: center;
  display: inline-block;
  margin: 0.2em 0.1em;
  margin-left: 1em;
  cursor: pointer;
  border-radius: 5px;
}
