body {
    font-family: "Nunito", sans-serif;
    color: #0c0c0c;
    background-color: #ffffff;
    max-width: auto;
    margin: 0 auto;
    padding: 0px 0px;
}

ul {
    list-style: none;
    padding: 0;
}

.main-group-container {
    padding: 20px 80px;
}

.dropdown {
    display: inline-block;
    position: relative;
}

.dropdown-options {
    display: none;
    flex-direction: column;
    position: absolute;
    overflow: auto;
    border-radius: 20px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    min-width: 200pt;
    z-index: 10;
}

.dropdown .visible {
    display: flex;
}

.dropdown-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    font-size: medium;
    font-family: 'Nunito', sans-serif;
    background-color: #EFEFF0;

    padding: 12px 16px;
    border: none;
    overflow: visible;
    text-align: left;
}

.dropdown-value:focus {
    background-color: #d9d9d9;
}

.sub-row{
    display: flex;
    align-items: center;
    gap: 12px;
}

.sub-btn{
    background-color: #5818DE;
    color: white;
    cursor: pointer;
    height: 40px; 
    border: none;
    border-radius: 50%; 
    display: inline-flex;
    align-items: center;
    overflow:hidden;
    width: auto;
    max-width: 40px; 
    -webkit-transition: max-width 0.5s;
    transition: max-width 0.5s;
}

.sub-btn:hover{
    max-width: 300px;
    border-radius: 60px;
}

.sub-btn:not(:hover) {
    -webkit-transition: max-width 0.5s , border-radius 0.5s 0.2s;
    transition: max-width 0.5s , border-radius 0.5s 0.2s;
}

.sub-btn img{
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px;
}

.sub-btn-txt{
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    white-space: nowrap;
    padding-left: 6px;
    padding-right: 8px;
}


.dropdown-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    font-size: medium;
    font-family: 'Nunito', sans-serif;
    background-color: #EFEFF0;
    border-radius: 20px;
    padding: 12px 16px;
    border: none;
    min-width: 200pt;
}

.dropdown-btn:active {
    background-color: #D9D9D9;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.balance-txt {
    font-size: large;
}

.balance-txt .amount {
    padding-left: 30px;
    font-weight: 700;
}

.positive {
    color: #1C8D70;
}

.negative {
    color: #E0421A;
}

.expense{
    color:#0c0c0c
}

.header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
    color: #61646B;
    font-size: small;
}

.header .icon {
    width: 40px;
}

.header .name {
    width: 40%;
}

.header .date {
    width: 20%;
}

.header .amount {
    flex-grow: 1;
    min-width: 100px;
}

.header .status {
    width: 120px;
}

.date-section {
    display: none;
}

.month-selector-row {
    display: flex;
    align-content: center;
    justify-content: center;
    padding-top: 10px;
}

.month-selector-container {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    background-color: #FAFAFA;
    border-radius: 20px;
    width: 350pt;
}

.month-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 8px 16px;
    border: none;
    
}

.month-selector-container .finance {
    padding: 10px;
    padding-top: 0px;
    display: flex;
    justify-content: space-around;
    font-size:small;
    color: #61646B;
}

.bal-con {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bal-con .amount {
    font-size: medium;
    font-weight: 700;
}

.bal-con .mobile-only {
    display: none;
}

.month-selector .btn {
    border: none;
    border-radius: 20px;
    padding: 10px 8px;
    border: none;
    background-color: #EFEFF0;
}

.month-selector .btn:active {
    background-color: #D9D9D9;
}

.month-selector .left {
    transform: rotateZ(90deg);
}

.month-selector .right {
    transform: rotateZ(-90deg);
}

@media only screen and (max-width: 400px) {
    .bal-con {
        flex-direction: row;
    }

    .finance {
        flex-direction: column;
    }
    .bal-con .mobile-only {
        display: block;
    }


}

@media only screen and (max-width: 950px) {
    .date-section {
        display: block;
        padding: 10px 0px;
        font-size: medium;
        font-family: 'Nunito', sans-serif;
    }

    .main-group-container {
        padding: 20px 20px;
    }
    .row {
        flex-direction: column;
    }
    .balance-txt {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
    }
    .transactions-list .header {
        display: none;
    }    

    .transactions-list {
        align-items: center;
        align-content: center;
    }
}

@media (max-width: 500px) {
    .sub-row {
        flex-direction: column;
        gap:0px;
        padding-bottom: 20px;
    }
}
