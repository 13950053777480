.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 3em;
  line-height: 1.6;
  font-size: 1.2em;
}

.container h1,
.introduction-text {
  text-align: center;
  font-weight: bold;
}

.container ol {
  padding-left: 1em;
}

.container li {
  margin-top: 1em;
}

.container li,
.highlight {
  font-weight: bold;
}

.introduction-text {
  font-size: 1.4em;
}

.step-title {
  display: inline-block;
  vertical-align: middle;
}

.flex-center,
.modal,
.badges {
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy-button {
  background-color: #1c8d70;
  color: white;
  border: none;
  padding: 0.5em 1em;
  text-align: center;
  display: inline-block;
  margin: 0.2em 0.1em;
  margin-left: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.copy-button:hover {
  background-color: #156b52;
}

.highlight {
  text-transform: uppercase;
  color: #1c8d70;
}

.instruction-text {
  max-width: 600px;
  font-weight: normal;
  text-align: start;
}

.instruction-image {
  cursor: pointer;
  max-height: 31.25em;
  border: 4px solid black;
  border-radius: 20px;
}

.badges div a,
.ios img,
.google img,
.rustore img {
  height: 5em;
}

.ios img {
  padding: 0.75em;
  box-sizing: border-box;
}

.rustore img {
  padding: 0.55em;
  box-sizing: border-box;
}

.footer-text {
  text-align: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.modal-image {
  width: 90%;
  height: 85%;
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

@media (max-width: 768px) {
  .container {
    padding: 1em;
  }

  .group-link-container {
    flex-direction: column;
    text-align: center;
  }

  .google img {
    height: 3.8em;
  }

  .ios img {
    padding: 1.2em 1.2em 1.2em 0;
  }

  .rustore img {
    padding: 1em 1em 1em 0;
  }
}

@media (max-width: 400px) {
  .google img {
    height: 3em;
  }

  .ios img {
    padding: 1.4em 1.4em 1.4em 0;
  }

  .rustore img {
    padding: 1.2em 1.2em 1.2em 0;
  }
}
