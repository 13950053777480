@font-face {
    font-family: 'Nunito';
    src: url(https://fonts.googleapis.com/css?family=Nunito);
  } 

  .slider__selection {
    
    display: flex;
    position: relative;    
    padding-top: 50px;
    background: linear-gradient(180deg, #218C72 7.81%, rgba(134, 192, 178, 0.55) 63.54%, rgba(187, 220, 212, 0.30) 90.1%, rgba(205, 229, 223, 0.22) 95.83%, rgba(255, 255, 255, 0.00) 100%);
  }

  .caorusel__inner {
    width: 100%;
  }

  .caorusel__item {
    position: relative;
    width: 100%;
}

.container {
    margin-right: auto;
    margin-left: auto;
}


.container__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 760px) {
    .container__row {
    flex-direction: column;
    align-items: center;
    }
}

.col__1 {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider__selection .detail__box {
    color: #ffffff;
    text-align: center;
    white-space: pre-wrap;
}

.slider__selection .detail__box  h1 {
    margin-top: 0;
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 30px;
}

p {
    font-size: 1.2rem;
    /* line-height: 1.5; */
}

.btn__box a {
    margin: 30px -5px 0px;
    display: flex;
    height: 60px;
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

@media (max-width: 760px) {
    .detail__box p {
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }
    .col__1 {
        padding-bottom: 50px;
        max-width: 100%;
        padding-right: 0px;
    }
    .slider__selection .detail__box  h1 {
        text-align: center;
        font-size: 3.5rem;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.btn-1 {
    display: inline-block;
    padding: 8px 20px;
    background-color: #1C8D70;
    color: #ffffff;
    border-radius: 30px;
    cursor: pointer;
    text-decoration: none;
}
a.btn-1:hover {
    background-color: #ffffff;
    color: #1C8D70;
}

@media (max-width: 760px) {
    .btn__box {
        text-align: center;
    }
}
.col__2 {
    max-width: 100%;
}


@media (max-width: 760px) {
    .col__2 {
    width: 100%;
    max-width: 100%;
    text-align: center;
    }
}

.image__box img {
    max-width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 760px) {
    .image__box img {
    width: 350px;
    }
}

