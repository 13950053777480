@font-face {
    font-family: 'Nunito';
    src: url(https://fonts.googleapis.com/css?family=Nunito);
  }

  .Privace__inner {
    background-color: white;
    display: block;
  }

.Privace__container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }



@media (min-width: 768px) {
    .Privace__container {
    max-width: 720px;
    }
}
@media (min-width: 992px) {
    .Privace__container {
    max-width: 960px;
    }
}
 
  .Privace__subpage .Privace__container {

  }