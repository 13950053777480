@font-face {
    font-family: 'Nunito';
    src: url(https://fonts.googleapis.com/css?family=Nunito);
  } 

  .service__section {
    background-color: #ffffff;
    color: #000000;
    text-align: center;
    padding-top: 100px;
}

.container__fluid {
    width: 100%;
    /* padding-right: 15px; */
    /* padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;
}

.heading__container {
    align-items: center;
}

.heading__container h1 {
    position: relative;
    font-weight: bold;
    line-height: 1.2;
    font-size: 2.5rem;
}

.service__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.row__container {
    width: 60%;
    border-radius: 20px;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 40px;
}

.row__container.center {
    flex-direction: column-reverse;
    align-items: center;
}

.feature__image {
    display: flex;
    height: 500px;
}

.center .feature__image {
    display:block;
}

.center .feature__image img {
    resize: both;
    height: 500px;
}


.row__container.row_odd {
    background-color: #1C8D701C!important;
}

.feature__texts {
    width: 80%;
    padding: 20px;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
}

.feature__texts .title {
    font-weight: bold;
    font-size: 1.5rem;
    text-align: start;
}

.feature__texts .description {
    padding-top: 60px;
    white-space: pre-wrap;
    text-align: start;
    font-weight: 400;
    line-height: 200%;
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .row__container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .feature__image {
        display:block;
    }
    
    .feature__image img {
        resize: both;
        height: 500px;
    }

    .row__container {
        width: 90%;
    }

    .center .feature__image img {
        resize: both;
        width: 100%;
        height: auto;
    }

    .row__container {
        padding: 10px;
    }
}