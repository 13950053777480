.cookies-banner {
    display: flex;
    justify-content: center;
    font-size: medium;
    font-family: 'Nunito', sans-serif;
    margin-bottom: 20px;
}

.cookies-banner .content {
    background-color:beige;
    padding: 20px;
    border-radius: 20px;
}

.cookies-banner .actions-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.cookies-banner .cookies-btn {
    border-radius: 16px;
    padding: 4px 8px;
    margin-top: 10px;
    border: none;
    background-color: #1C8D70;
    font-size: medium;
    font-family: 'Nunito', sans-serif;
    color: white;
}

.cookies-banner .reject {
    background-color: #E0421A;
}

@media only screen and (max-width: 800px) {
    .cookies-banner {
        margin: 20px;
    }
}
